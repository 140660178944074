<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="4">
        <div
          class="elevation-10 inline-flex items-center justify-center w-full rounded h-[100px]"
        >
          <img
            src="../assets/icons/fingerprint-scan.gif"
            class="w-20 h-20 inline mr-1"
          />
          <div class="inline-flex justify-center items-center w-[70%] h-full">
            <span class="text-black text-900 text-[14px]"
              >Xodimlarning ma'lumotlari soni:</span
            >
          </div>
          <div class="inline-flex justify-center items-center w-[30%] h-full">
            <span class="text-black text-900 text-[16px]">{{
              dashboard.passports_count
            }}</span>
          </div>
        </div>
      </v-col>
      <v-col cols="4">
        <div
          class="elevation-10 inline-flex items-center justify-center w-full rounded h-[100px]"
        >
          <img src="../assets/icons/robot.gif" class="w-20 h-20 inline mr-1" />
          <div class="inline-flex justify-center items-center w-[70%] h-full">
            <span class="text-black text-900 text-[14px]"
              >Botdan foydalanadigan xodimlar soni:</span
            >
          </div>
          <div class="inline-flex justify-center items-center w-[30%] h-full">
            <span class="text-black text-900 text-[16px]">{{
              dashboard.clients_count
            }}</span>
          </div>
        </div>
      </v-col>
      <v-col cols="4">
        <div
          class="elevation-10 inline-flex items-center justify-center w-full rounded h-[100px]"
        >
          <img
            src="../assets/icons/scanning.gif"
            class="w-20 h-20 inline mr-1"
          />
          <div class="inline-flex justify-center items-center w-[70%] h-full">
            <span class="text-black text-900 text-[14px]"
              >Shu oyda listlar necha martta ko'rildi:</span
            >
          </div>
          <div class="inline-flex justify-center items-center w-[30%] h-full">
            <span class="text-black text-900 text-[16px]">{{
              dashboard.watched_lists_count
            }}</span>
          </div>
        </div>
      </v-col>
    </v-row>
    <div class="mt-5 elevation-10 pa-3">
      <div class="flex justify-center">
        <span class="text-black text-900 text-[14px]"
          >Oxirgi ko'rilgan listlar...</span
        >
      </div>
      <div class="flex justify-center mb-3">
        <img
          src="../assets/icons/network-eye.gif"
          class="w-20 h-20 inline mr-1"
        />
      </div>
      <v-row dense>
        <v-col
          cols="4"
          v-for="(list, index) of dashboard.last_watched_lists"
          :key="index"
        >
          <v-textarea
            v-model="list.list"
            outlined
            style="font-size: 12px"
            auto-grow
            readonly
            hide-details
          />
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import axios from "../axios";

export default {
  name: "Main",
  data: () => ({
    dashboard: {
      passports_count: 0,
      clients_count: 0,
      watched_lists_count: 0,
      last_watched_lists: [],
    },
  }),
  created() {
    this.fetchDashboard();
  },
  methods: {
    async fetchDashboard() {
      try {
        this.$overlay(true);

        const { data: dashboard } = await axios.get("/dashboards");
        this.dashboard = dashboard;
      } catch (error) {
        this.$toast.error(error.response?.data?.message | error.message | "");
      } finally {
        this.$overlay(false);
      }
    },
  },
};
</script>
